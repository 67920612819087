import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {getPerformance} from "firebase/performance";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDJlKQ9lhQUb_E65UwjMLec64E43IA-pUg",
    authDomain: "lyceumua-5950a.firebaseapp.com",
    projectId: "lyceumua-5950a",
    storageBucket: "lyceumua-5950a.appspot.com",
    messagingSenderId: "672692224846",
    appId: "1:672692224846:web:04d71ffeb595a94843d41f",
    measurementId: "G-X947002Y50"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const perf = getPerformance(app);

const rootElement = document.getElementById('root') as HTMLElement;
rootElement.oncontextmenu = ev => ev.preventDefault();
const root = ReactDOM.createRoot(rootElement);
root.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(send);

// @ts-ignore
function send({name, delta, value, id}) {
    // @ts-ignore
    gtag('event', name, {
        // Built-in params:
        value: delta, // Use `delta` so the value can be summed.
        // Custom params:
        metric_id: id, // Needed to aggregate events.
        metric_value: value, // Optional.
        metric_delta: delta, // Optional.
    });
}
import React, {useEffect, useMemo} from 'react';
import iOS from "./ios.utils";
import logo from './logo.webp';
import schoolImg from './school-20.webp'
import schoolSvg from './school.webp'
import fb from './facebook.svg';
import insta from './instagram.svg';
import './App.css';

function App() {

    const isIOS = useMemo(() => iOS(), []);

    useEffect(() => {
        const elements = document.getElementsByClassName('camp-wrapper');
        const el = elements[0];
        if (isIOS) {
            el.classList.add('ios');
        } else {
            el.classList.remove('ios');
        }
    }, [isIOS]);

    return (
        <div className="App">
            <div className="topbar">
                <div className="container">
                    <div className="flex flex-space-between">
                        <a href="mailto:info@lyceumua.eu">info@lyceumua.eu</a>
                        <a href="tel:+359877541668">+359877541668</a>
                    </div>
                </div>
            </div>
            <header className="header">
                <div className="container">
                    <div className="flex flex-space-between">
                        <a href="#">
                            <img src={logo} width={64} height={64} alt="Ліцей Український Інтелект"/>
                        </a>
                        <div className="flex flex-column flex-align-center">
                            <a className="fb-icon" href="https://www.facebook.com/profile.php?id=61560470163340" rel="noreferrer"
                               target="_blank">
                                <img src={fb} alt="fb" width={32} height={32}></img>
                            </a>
                            <a className="insta-icon" href="https://www.instagram.com/lyceumuaeu/" rel="noreferrer" target="_blank">
                                <img src={insta} alt="instagram" width={32} height={32}></img>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="site-header__decor">
                    <div className="site-header__decor-row">
                        <div className="site-header__decor-single">
                            <div className="site-header__decor-inner-1"></div>
                        </div>
                        <div className="site-header__decor-single">
                            <div className="site-header__decor-inner-2"></div>
                        </div>
                    </div>
                </div>
            </header>
            <div className="banner-wrapper">
                <div className="banner">
                    <div className="container">
                        <h1 className="banner-title">Ліцей <b>Український Інтелект</b></h1>
                        <div className="banner-bubble-1"/>
                        <div className="banner-bubble-2"/>
                        <div className="banner-bubble-3"/>
                    </div>
                </div>
            </div>
            <div className="about-one">
                <div className="container">
                    <div className="flex flex-align-center" style={{filter: "opacity(0.5)"}}>
                        <img width={80} height={80} src={schoolImg} alt="Ліцей Український Інтелект"/>
                    </div>
                    <h1 className="about-one__title">Прийом учнів 1-11 класів на наступний навчальний рік</h1>
                    <div className="about-one__text">
                        Ми віримо, що навчання – це захоплюючий процес, а кожна дитина може відкрити свій власний світ
                        можливостей. Ми допомагаємо юним розумам зростати, розкриваючи їх потенціал і допомагаючи
                        зберегти радість від вивчення.
                        <br/>
                        Разом ми покликані робити кроки вперед, відкривати нові горизонти і вирощувати майбутніх
                        лідерів, зберігаючи доброзичливу атмосферу і прагнення до знань.
                    </div>
                    <div className="about-one__btn">
                        <a className="thm-btn" target="_blank" rel="noreferrer"
                           href="https://docs.google.com/forms/d/e/1FAIpQLSf-JF9CR4f5LCYg7iegJ9iIwY4vakrZ0C-urWQumef7NdDIMg/viewform">
                            Реєструйся
                        </a>
                    </div>
                </div>
            </div>
            <div className="about-two">
                <div className="camp-wrapper">
                    <div className="camp">
                        <div className="container">
                            <h1 className="about-two__title">Літній табір<br/><b>Друзі</b></h1>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="about-two__text">
                        Ви шукаєте незабутні пригоди та нових друзів для своєї дитини цього літа? Літній
                        Шкільний табір "Друзі" – ідеальне місце для веселих і пізнавальних канікул! Найкраще
                        літо для вашої дитини починається тут!
                    </div>
                    <h2 className="about-two__title">Що ми пропонуємо?</h2>
                    <div className="about-two__text">
                        <ul>
                            <li>Активні розваги: Спортивні ігри, походи та багато іншого.</li>
                            <li>Навчальні програми: Майстер-класи, креативні заняття, вивчення іноземних мов.</li>
                            <li>Екскурсії: Відвідування музеїв, природних заповідників та цікавих місць.</li>
                            <li>Творчість: Арт-терапія, музика, танці, театральні постановки.</li>
                            <li>Безпека: Постійний нагляд досвідчених вихователів.</li>
                        </ul>
                    </div>
                    <div className="about-two__text">
                        <b>Дати проведення:</b> з 17 червня до 25 серпня. (Дві зміни)
                        <br/>
                        <b>Вік учасників:</b> від 6 до 14 років.
                        <br/>
                        Не втрачайте можливості забезпечити своїй дитині незабутні канікули!
                    </div>
                    <div className="about-two__btn">
                        <a className="thm-btn" target="_blank" rel="noreferrer"
                           href="https://docs.google.com/forms/d/e/1FAIpQLSc3TnkCcwUelCye3Jyt84mXEQ19PTqeakEvCPZFv_mAXH2SuA/viewform">
                            Приєднуйся
                        </a>
                    </div>
                </div>
            </div>
            <div className="about-three">
                <div className="about-three__banner">
                    <div className="container">
                        <h1 className="about-three__title">Підготовка до школи</h1>
                    </div>
                    <div className="about-three__curve"/>
                </div>
                <div className="container">
                    <div className="about-three__text">
                        Ваша дитина скоро йде до школи? Допоможіть їй зробити цей перехід легким та успішним!
                        <h2 className="about-three__title">Що ми пропонуємо:</h2>
                        <ul>
                            <li>Заняття з основних предметів — читання, письмо, математика, логіка, англійська та
                                болгарська
                                мови.
                            </li>
                            <li>Розвиток дрібної моторики, пам'яті, уваги та мислення.</li>
                            <li>Соціальна адаптація: навчання взаємодії з однолітками, розвиток комунікативних
                                навичок.
                            </li>
                        </ul>
                        <h2 className="about-three__title">Наші переваги:</h2>
                        <ul>
                            <li>Досвідчені викладачі.</li>
                            <li>Індивідуальний підхід: ми враховуємо індивідуальні особливості кожної дитини, щоб
                                навчання було максимально ефективним.
                            </li>
                            <li>Сучасні методики: використовуємо новітні методики та інтерактивні матеріали для цікавого
                                навчання.
                            </li>
                            <li>Матеріали для самостійної роботи: роздаткові матеріали та завдання для закріплення
                                знань.
                            </li>
                        </ul>
                        Готуємо майбутніх першокласників разом!
                        <br/>
                        Забезпечте своїй дитині впевнений старт у навчанні та незабутні враження від перших кроків у
                        школі!
                    </div>
                </div>
                <div className="about-three__btn">
                    <a className="thm-btn" target="_blank" rel="noreferrer"
                       href="https://docs.google.com/forms/d/e/1FAIpQLSei4q1XC1sZcnuJufzGh7DMouQeKyheL_va-Iuv6G0xCS8qgA/viewform">
                        Підготовка
                    </a>
                </div>
            </div>
            <div className="footer">
                <div className="flex flex-align-center" style={{filter: "opacity(0.5)"}}>
                    <img width={80} height={80} src={schoolSvg} alt="Ліцей Український Інтелект"/>
                </div>
                <div className="container">
                    <div className="footer__contacts">
                        <h1 className="footer__contacts-title">Контакти</h1>
                        <div className="flex flex-column flex-valign-end">
                            <div className="flex flex-column flex-valign-end">
                                <a className="monospaced flex flex-column flex-valign-end"
                                   href="https://maps.app.goo.gl/GsTFrw1CXQtJDGGT7" target="_blank" rel="noreferrer">
                                    <div className="monospaced">вул. Родопські Ізвор 60</div>
                                    <div className="monospaced">1680 Софія, Болгарія</div>
                                </a>
                            </div>
                            <a className="monospaced" href="tel:+359877541668">+359877541668</a>
                            <a className="monospaced" href="tel:+359882246920">+359882246920</a>
                            <a className="monospaced" href="mailto:info@lyceumua.eu">info@lyceumua.eu</a>
                            <div className="flex">
                                <a className="fb-icon" href="https://www.facebook.com/profile.php?id=61560470163340" rel="noreferrer"
                                   target="_blank">
                                    <img src={fb} alt="fb" width={32} height={32}></img>
                                </a>
                                <a className="insta-icon" href="https://www.instagram.com/lyceumuaeu/" rel="noreferrer" target="_blank">
                                    <img src={insta} alt="instagram" width={32} height={32}></img>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="footer__map">
                        <iframe
                            title="google-maps"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d366.7218006454423!2d23.282090002261373!3d42.66613580000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40aa85e84d2bb41b%3A0x64d7a1bf56e47af9!2z0KPQutGA0LDRl9C90YHRjNC60LjQuSDQhtC90YLQtdC70LXQutGC!5e0!3m2!1sen!2sbg!4v1720547793008!5m2!1sen!2sbg"
                            width="100%" height="450" style={{border: 0}} allowFullScreen={false} loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
                <div className="copyright">©Copyright 2024 by <a className="link" href="https://lyceumua.eu">lyceumua.eu</a></div>
            </div>
        </div>
    );
}

export default App;